import request from '@/utils/request'

// 仓库积压
export function getList(data) {
  return request({
    url: '/customerAdmin/warning/overstock-list',
    method: 'post',
    data,
  })
}

// 规则列表
export function rulesList(data) {
  return request({
    url: '/customerAdmin/warning/rule-list',
    method: 'post',
    data,
  })
}

// 规则详情
export function rulesDetail(data) {
  return request({
    url: '/customerAdmin/warning/overstock-view',
    method: 'post',
    data,
  })
}
// 创建/修改规则
export function rulesEdit(data) {
  return request({
    url: '/customerAdmin/warning/overstock-modify',
    method: 'post',
    data,
  })
}

// 删除规则
export function rulesDelete(data) {
  return request({
    url: '/customerAdmin/warning/overstock-delete',
    method: 'post',
    data,
  })
}

// 仓库缺货
export function getList2(data) {
  return request({
    url: '/customerAdmin/warning/scarce-list',
    method: 'post',
    data,
  })
}

// 采购临期
export function getList3(data) {
  return request({
    url: '/customerAdmin/warning/impending-list',
    method: 'post',
    data,
  })
}

/**
 * 长期未拜访
 */

// 长期未拜访(漏店)预警
export function visitList(data) {
  return request({
    url: '/customerAdmin/warning/cycle-list',
    method: 'post',
    data,
  })
}

/**
 * 长期未订货
 */

// 长期未订货(流失)预警
export function notOrderList(data) {
  return request({
    url: '/customerAdmin/warning/loss-list',
    method: 'post',
    data,
  })
}

/**
 * /customerAdmin/warning/owed-list
欠款预警列表
 */

// 欠款预警列表
export function arrearsList(data) {
  return request({
    url: '/customerAdmin/warning/owed-list',
    method: 'post',
    data,
  })
}
/**
 * 停留超时
 */

// 停留超时
export function timeOutList(data) {
  return request({
    url: '/customerAdmin/warning/stop-list',
    method: 'post',
    data,
  })
}

// 更新超时预警配置
export function setTimeOut(data) {
  return request({
    url: '/baseAdmin/dealer-config/set-stop-remain',
    method: 'post',
    data,
  })
}

// 角色选框列表
export function roleList(data) {
  return request({
    url: '/baseAdmin/common/role-list',
    method: 'post',
    data,
  })
}
