import request from '@/utils/request'

// 首屏表格
export function getIndexList(data) {
  return request({
    url: '/saleAdmin/scheme/index',
    method: 'post',
    data,
  })
}

// 查询后台业务员
export function findStaff(data) {
  return request({
    url: '/baseAdmin/common/user-list',
    method: 'post',
    data,
  })
}

// 详情
export function getDetail(data) {
  return request({
    url: '/saleAdmin/scheme/view',
    method: 'post',
    data,
  })
}

// 员工设置 树

export function getTree(data) {
  return request({
    url: '/baseAdmin/user/window-view',
    method: 'post',
    data,
  })
}

// 用户表格数据
export function getUserData(data) {
  return request({
    url: '/baseAdmin/user/window-view-do',
    method: 'post',
    data,
  })
}
// 保存修改
export function saveData(data) {
  return request({
    url: '/saleAdmin/scheme/update',
    method: 'post',
    data,
  })
}
// 删除row

export function deleteRow(data) {
  return request({
    url: '/saleAdmin/scheme/delete',
    method: 'post',
    data,
  })
}
// 创建

export function createPlan(data) {
  return request({
    url: '/saleAdmin/scheme/create',
    method: 'post',
    data,
  })
}

// 获取 关联商品 表格数据
export function getLinkGoodsList(data) {
  return request({
    url: '/saleAdmin/scheme/goods-list',
    method: 'post',
    data,
  })
}
// 调拨价方案关联商品用的
export function getLinkGoodsListT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/goods-list',
    method: 'post',
    data,
  })
}
// 获取 批量天机 关联商品 表格数据
export function getBatchLinkGoodsList(data) {
  return request({
    url: '/saleAdmin/scheme/no-goods-list',
    method: 'post',
    data,
  })
}
// 调拨用 批量添加商品
export function getBatchLinkGoodsListT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/no-goods-list',
    method: 'post',
    data,
  })
}

// 实时修改关联商品价格
export function setLinkGoods(data) {
  return request({
    url: '/saleAdmin/scheme/goods-update',
    method: 'post',
    data,
  })
}
//
// 调拨-更新关联商品的字段值
export function setLinkGoodsT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/goods-update',
    method: 'post',
    data,
  })
}

// 删除/批量删除关联的商品
export function deleteBrandRow(data) {
  return request({
    url: '/saleAdmin/scheme/goods-delete',
    method: 'post',
    data,
  })
}
// 批量删除 关联的商品 调拨价用
export function deleteBrandRowT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/goods-delete',
    method: 'post',
    data,
  })
}

// 批量添加 关联商品
export function batchAddGoods(data) {
  return request({
    url: '/saleAdmin/scheme/goods-set',
    method: 'post',
    data,
  })
}
// 调拨用 批量添加 关联商品
export function batchAddGoodsT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/goods-set',
    method: 'post',
    data,
  })
}
// 批量调整价格
export function linkGoodsSetPrice(data) {
  return request({
    url: '/saleAdmin/scheme/goods-batch-price',
    method: 'post',
    data,
  })
}
// 调拨方案加用 批量调整价格

export function linkGoodsSetPriceT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/goods-batch-price',
    method: 'post',
    data,
  })
}

// 客户等级下拉
export function getClientLevelList(data) {
  return request({
    url: '/baseAdmin/common/cust-class-option',
    method: 'post',
    data,
  })
}

// 客户渠道
export function getClientChannelList(data) {
  return request({
    url: '/baseAdmin/common/cust-channel-option',
    method: 'post',
    data,
  })
}

/**
 * 关联客户
 */
// 弹窗获取已添加客户

export function linkClientIndexList(data) {
  return request({
    url: '/saleAdmin/scheme/cust-list',
    method: 'post',
    data,
  })
}

// 业务员下拉列表
export function getStaffList(data) {
  return request({
    url: '/baseAdmin/common/staff-option',
    method: 'post',
    data,
  })
}
// 批量添加客户表格数据
export function getLinkClientTableList(data) {
  return request({
    url: '/saleAdmin/scheme/no-cust-list',
    method: 'post',
    data,
  })
}

// 批量添加到关联客户
export function addLinkClient(data) {
  return request({
    url: '/saleAdmin/scheme/customer-set',
    method: 'post',
    data,
  })
}

// 删除/批量删除 关联的客户
export function deleteClientRow(data) {
  return request({
    url: '/saleAdmin/scheme/customer-delete',
    method: 'post',
    data,
  })
}
// 打印需要的批量删除
export function deleteClientRow2(data) {
  return request({
    url: '/setAdmin/print/customer-delete',
    method: 'post',
    data,
  })
}

/**
 *
 * @param {客户资料API} data
 * @returns
 */
// 终端关联业务员列表
export function getRightStaff(data) {
  return request({
    url: '/customerAdmin/customer-rel/index',
    method: 'post',
    data,
  })
}
// / 终端关联业务员列表 调拨用
export function getRightStaffT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/user-list',
    method: 'post',
    data,
  })
}

// 设置终端关联业务员
export function saveStaff(data) {
  return request({
    url: '/customerAdmin/customer-rel/set',
    method: 'post',
    data,
  })
}

// 关联员工提交 调拨用
export function saveStaffT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/user-set',
    method: 'post',
    data,
  })
}

// 任务管理关联人员默认
export function defaultStaff(data) {
  return request({
    url: '/baseAdmin/common/user-list',
    method: 'post',
    data,
  })
}
// 关联业务员 列表 调拨价用
export function defaultStaffT(data) {
  return request({
    url: '/saleAdmin/allot-scheme/user-list',
    method: 'post',
    data,
  })
}
